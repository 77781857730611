<template>
  <Layout :hbgsrc="bg">
    <div class="modal flex" slot="slogan">
      <div class="box">
        <h4 class="h4" v-if="$store.state.isPc">
          共同、共生、共赢，共建零碳世界新生态
        </h4>
        <h4 class="h4" v-else>共同、共生、共赢<br />共建零碳世界新生态</h4>
        <p style="width: 566px">
          随着低碳经济成为我国现经济发展的主旋律，除了政策支持，各企业也加快布局新能源战略。
          联合具备行业资源、场地资源、资金资源等能力的生态伙伴共同打造零碳生态解决方案，助力碳中和，构建低碳经济社会体系，实现可持续发展。政策驱动，市场利好，把握行业发展趋势，寻找最佳机会与商机。
        </p>
        <el-button class="join" v-if="$store.state.isPc">加入我们</el-button>
      </div>
    </div>
    <template slot="body">
      <MainSlogan>
        <template slot="title">为什么选择我们？</template>
      </MainSlogan>
      <template v-if="!$store.state.isPc">
        <section class="one">
          <div class="list">
            <div class="item flex" v-for="item in oneList" :key="item.id">
              <div class="item-left">
                <el-image :src="item.src" fit="fit"></el-image>
              </div>
              <div class="item-right">
                <h5>{{ item.title }}</h5>
                <p v-html="item.des"></p>
              </div>
            </div>
          </div>
        </section>
        <section class="two">
          <MainSlogan>
            <template slot="title">我们能够为你提供</template>
          </MainSlogan>
          <div class="list">
            <div class="item flex" v-for="item in twoList" :key="item.id">
              <div class="item-left">
                <el-image
                  :src="item.src"
                  fit="fit"
                  :class="[item.id == 3 ? 'bg' : '']"
                ></el-image>
              </div>
              <div class="item-right">
                <h5>{{ item.title }}</h5>
                <p v-html="item.des"></p>
              </div>
            </div>
          </div>
        </section>
        <section class="three">
          <MainSlogan>
            <template slot="title">成为生态合作伙伴的流程</template>
          </MainSlogan>
          <div class="list">
            <div class="item flex" v-for="item in threeList" :key="item.id">
              <div class="item-left flex">
                <el-image
                  :src="item.src"
                  fit="fit"
                  :class="[!item.small ? 'bg' : '']"
                ></el-image>
              </div>
              <div class="item-right">
                <h4>{{ item.title }}</h4>
                <p v-html="item.des"></p>
              </div>
            </div>
          </div>
        </section>
        <section class="four">
          <el-image :src="four" fit="cover" class="bg"></el-image>
          <div class="four-modal">
            <MainSlogan>
              <template slot="title">合作模式</template>
            </MainSlogan>
            <div class="list">
              <div class="item flex" v-for="item in fourList" :key="item.id">
                <div class="item-left flex">
                  <h5>{{ item.title }}</h5>
                  <p v-html="item.des"></p>
                </div>
                <div class="item-right">
                  <el-image :src="item.src" fit="fit"></el-image>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="five">
          <MainSlogan>
            <template slot="title">生态合作伙伴</template>
          </MainSlogan>
          <div class="list">
            <div class="item flex" v-for="item in fiveList" :key="item.id">
              <div class="item-left flex">
                <el-image :src="item.src" fit="cover"></el-image>
              </div>
              <div class="item-right">
                <div class="name flex">
                  <el-image :src="item.img" fit="fit"></el-image>
                  <h6>{{ item.title }}</h6>
                </div>
                <p v-html="item.des"></p>
              </div>
            </div>
          </div>
        </section>
        <section class="six">
          <MainSlogan>
            <template slot="title">誉照的合作伙伴</template>
          </MainSlogan>
          <div class="list flex">
            <div class="item flex bb" v-for="item in sixList" :key="item.id">
              <el-image
                :src="item.src"
                fit="contain"
                :class="[item.size]"
              ></el-image>
            </div>
          </div>
        </section>
      </template>
      <template v-if="$store.state.isPc">
        <section class="pic-box">
          <PicWall :pic="pic"></PicWall>
        </section>
        <MainSlogan>
          <template slot="title">我们能够为你提供</template>
        </MainSlogan>
        <section class="thumbnail flex">
          <ul class="flex">
            <li class="bb">
              <el-image :src="tigong1"></el-image>
              <p class="flex">
                <span class="p-title">业务赋能</span>
                <span>数字化工具辅助分析</span>
                <span>成功案例赋能客户</span>
              </p>
            </li>
            <li class="bb">
              <el-image :src="tigong2"></el-image>
              <p class="flex">
                <span class="p-title">产品赋能</span>
                <span>工艺级产品使用体验</span>
                <span>精细化产品功能定位</span>
              </p>
            </li>
            <li class="bb">
              <el-image :src="tigong3"></el-image>
              <p class="flex">
                <span class="p-title">服务赋能</span>
                <span>针对性组织全方位培训</span>
                <span>对项目提供协同与复盘服务</span>
              </p>
            </li>
            <li class="bb">
              <el-image :src="tigong4"></el-image>
              <p class="flex">
                <span class="p-title">技术赋能</span>
                <span>丰富便捷的API接口</span>
                <span>专业活跃的开发者社区</span>
                <span>及时响应的技术支持</span>
              </p>
            </li>
          </ul>
        </section>
        <MainSlogan>
          <template slot="title">成为生态合作伙伴的流程</template>
        </MainSlogan>
        <section class="pic-box pic2-box">
          <PicWall :pic="pic2"></PicWall>
        </section>
        <!-- 合作模式 -->
        <section class="partner-mode">
          <div class="bg">
            <el-image :src="bg2" fit="cover"></el-image>
          </div>
          <div class="mode-box">
            <h4 class="h4">合作模式</h4>
            <div class="mode-list">
              <ul class="list flex">
                <li class="item flex bb">
                  <el-image :src="mode1"></el-image>
                  <h5 class="flex">供应链合作</h5>
                  <div class="modal flex bb">
                    <h5>供应链合作</h5>
                    <p>
                      如果你是行业供应商（充电设备零部件供应商、生产用设备供应商、IT设备供应商、物流服务商、安装服务商、各领域专业能力供应商），与誉照构筑信息共享、服务支持、并行工程、群体决策的合作关系，一起携手并进共发展。
                    </p>
                    <div class="icon flex">
                      <i class="el-icon-right"></i>
                    </div>
                  </div>
                </li>
                <li class="item flex bb">
                  <el-image :src="mode2"></el-image>
                  <h5 class="flex">城市合伙人</h5>
                  <div class="modal flex bb">
                    <h5>城市合伙人</h5>
                    <p>
                      如果你是中小运营商、公共单位、个人投资者或是地产开发商、偏好重资产的投资公司、大型运营商，我们针对性制定光伏、充电和储能解决方案，建立城市合作，实现互利共赢。
                    </p>
                    <div class="icon flex">
                      <i class="el-icon-right"></i>
                    </div>
                  </div>
                </li>
                <li class="item flex bb">
                  <el-image :src="mode3"></el-image>
                  <h5 class="flex">技术合作</h5>
                  <div class="modal flex bb">
                    <h5>技术合作</h5>
                    <p>
                      如果你是软件设计开发商，擅于研究平台互联互通、测试认证、联合研发，诚邀您与我们一同促进行业技术发展，为零碳技术领域开辟新的阳光大道。
                    </p>
                    <div class="icon flex">
                      <i class="el-icon-right"></i>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <MainSlogan>
          <template slot="title">生态合作伙伴</template>
        </MainSlogan>

        <section class="p-swiper">
          <div class="p-swiper-box">
            <div class="swiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="item in company" :key="item">
                  <div class="swiper-cnt flex">
                    <img
                      class="swiper-img"
                      :src="item.src"
                      alt=""
                      ref="image"
                    />
                    <div class="swiper-text flex">
                      <h4 class="h4">
                        <el-image :src="avatar"></el-image>{{ item.cname }}
                      </h4>
                      <p>{{ item.words }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="custom-swiper-button-prev flex">
            <i class="el-icon-arrow-left"></i>
          </div>
          <div class="custom-swiper-button-next flex">
            <i class="el-icon-arrow-right"></i>
          </div>
        </section>
        <MainSlogan>
          <template slot="title">誉照的合作伙伴</template>
        </MainSlogan>
        <section class="company">
          <!-- <marquee> -->
          <div class="swiper swiper-one">
            <div class="swiper-box flex">
              <div class="swiper-item" v-for="item in logoList" :key="item.id">
                <div class="swiper-cnt flex">
                  <img class="swiper-img" :src="item.src" alt="" ref="image" />
                </div>
              </div>
            </div>
          </div>
          <div class="swiper swiper-two">
            <div class="swiper-box flex">
              <div class="swiper-item" v-for="item in logoList2" :key="item.id">
                <div class="swiper-cnt flex">
                  <img class="swiper-img" :src="item.src" alt="" ref="image" />
                </div>
              </div>
            </div>
          </div>
          <!-- </marquee> -->
        </section>
      </template>
      <Applyin></Applyin>
    </template>
  </Layout>
</template>

<script>
  import Swiper from "swiper";
  import "swiper/css/swiper.min.css";
  import { Component, Vue } from "vue-property-decorator";
  import Layout from "@/components/Layout.vue"; // @ is an alias to /src
  import WordOnPic from "@/components/common/WordOnPic.vue";
  import MainSlogan from "@/components/common/MainSlogan.vue";
  import PicWall from "@/components/common/PicWall.vue";
  import Applyin from "@/components/common/Applyin.vue";

  @Component({
    components: {
      Layout,
      WordOnPic,
      MainSlogan,
      PicWall,
      Applyin,
    },
  })
  export default class ZBpartner extends Vue {
    data() {
      return {
        bg: require("@/assets/ZBpartner/img/bg.png"),
        bg2: require("@/assets/ZBpartner/img/bg2.png"),
        pic: require("@/assets/ZBpartner/img/pic.png"),
        pic2: require("@/assets/ZBpartner/img/pic1.png"),
        mode1: require("@/assets/ZBpartner/img/mode1.png"),
        mode2: require("@/assets/ZBpartner/img/mode2.png"),
        mode3: require("@/assets/ZBpartner/img/mode3.png"),
        tigong1: require("@/assets/ZBpartner/img/tigong1.png"),
        tigong2: require("@/assets/ZBpartner/img/tigong2.png"),
        tigong3: require("@/assets/ZBpartner/img/tigong3.png"),
        tigong4: require("@/assets/ZBpartner/img/tigong4.png"),
        avatar: require("@/assets/ZBpartner/img/avatar.png"),
        swiperButton: require("@/assets/home/img/icon_arrow_r.png"),
        four: require("@/assets/ZBpartner/img/four1.png"),
        company: [
          {
            src: require("@/assets/ZBpartner/img/tuya.png"),
            cname: "涂鸦智能",
            words:
              "誉照联合涂鸦共同打造智联万物的云产品及解决方案，打造互联互通的开发标准，通过技术应用使广大用户在安全、稳定的云环境之上，生活更为便利。智能解决方案赋能客户提升产品价值，为数字化业务与现代化应用浇筑坚实的基础，拓宽低碳智能生态的阳光大道。",
          },
          {
            src: require("@/assets/ZBpartner/img/huawei.png"),
            cname: "华为",
            words:
              "誉照联合华为持续发力电动充电基础设施领域，在交通脱碳进程中，一起以创新科技推动充电基础设施升级，解决车主充电痛点，拓宽市场需求，顺应“新基建”潮流开辟了充电体验的新境界。",
          },
          {
            src: require("@/assets/ZBpartner/img/jingke.png"),
            cname: "晶科能源",
            words:
              "在环境可持续性方面我们与晶科不约而同达成了共识，与晶科合作向全球输送清洁能源产品，布局光伏建筑一体化，帮助机构、医院、家庭实现清洁能源自发自用，共同打造新能源生态圈。",
          },
        ],
        logoList: [
          {
            id: 1,
            src: require("@/assets/ZBpartner/img/woer.png"),
          },
          {
            id: 2,
            src: require("@/assets/ZBpartner/img/huawei1.png"),
          },
          {
            id: 3,
            src: require("@/assets/ZBpartner/img/xingxing.png"),
          },
          {
            id: 4,
            src: require("@/assets/ZBpartner/img/yidong.png"),
          },
          {
            id: 5,
            src: require("@/assets/ZBpartner/img/diwen.png"),
          },
          {
            id: 6,
            src: require("@/assets/ZBpartner/img/woer.png"),
          },
          {
            id: 7,
            src: require("@/assets/ZBpartner/img/huawei1.png"),
          },
          {
            id: 8,
            src: require("@/assets/ZBpartner/img/xingxing.png"),
          },
          {
            id: 9,
            src: require("@/assets/ZBpartner/img/yidong.png"),
          },
          // {
          //   id: 10,
          //   src: require("@/assets/ZBpartner/img/diwen.png")
          // },
        ],
        logoList2: [
          {
            id: 1,
            src: require("@/assets/ZBpartner/img/zhanyu.png"),
          },
          {
            id: 2,
            src: require("@/assets/ZBpartner/img/yidian.png"),
          },
          {
            id: 3,
            src: require("@/assets/ZBpartner/img/lkk.png"),
          },
          {
            id: 4,
            src: require("@/assets/ZBpartner/img/tuya1.png"),
          },
          {
            id: 5,
            src: require("@/assets/ZBpartner/img/jingke1.png"),
          },
          {
            id: 6,
            src: require("@/assets/ZBpartner/img/taixi.png"),
          },
          {
            id: 7,
            src: require("@/assets/ZBpartner/img/zhengtai.png"),
          },
          {
            id: 8,
            src: require("@/assets/ZBpartner/img/zhanyu.png"),
          },
          {
            id: 9,
            src: require("@/assets/ZBpartner/img/yidian.png"),
          },
          {
            id: 10,
            src: require("@/assets/ZBpartner/img/lkk.png"),
          },
          {
            id: 11,
            src: require("@/assets/ZBpartner/img/tuya1.png"),
          },
          {
            id: 12,
            src: require("@/assets/ZBpartner/img/jingke1.png"),
          },
          {
            id: 13,
            src: require("@/assets/ZBpartner/img/taixi.png"),
          },
          // {
          //   id: 14,
          //   src: require("@/assets/ZBpartner/img/zhengtai.png")
          // },
        ],
        oneList: [
          {
            id: 1,
            src: require("@/assets/ZBpartner/img/one1.png"),
            title: "服务平台",
            des: "先进的综合管理平台数字化<br />可视化数据精确的计算系统<br />完善的风险预告机制",
          },
          {
            id: 2,
            src: require("@/assets/ZBpartner/img/one2.png"),
            title: "投资决策",
            des: "精准选址、专业建议、合作保护<br />规避投资风险",
          },
          {
            id: 3,
            src: require("@/assets/ZBpartner/img/one3.png"),
            title: "营销推广",
            des: "全方位推广渠道，定期主导推广活动<br />战略伙伴赋能",
          },
          {
            id: 4,
            src: require("@/assets/ZBpartner/img/one4.png"),
            title: "协同合作",
            des: "平台对接、方案共创<br />共享数字化服务",
          },
          {
            id: 5,
            src: require("@/assets/ZBpartner/img/one5.png"),
            title: "多渠道收益",
            des: "电站收益、商城收益、<br/>服务收益",
          },
        ],
        twoList: [
          {
            id: 1,
            src: require("@/assets/ZBpartner/img/two1.png"),
            title: "业务赋能",
            des: "数字化工具辅助分析<br />成功案例赋能客户",
          },
          {
            id: 2,
            src: require("@/assets/ZBpartner/img/two2.png"),
            title: "产品赋能",
            des: "工艺级产品使用体验<br />精细化产品功能定位",
          },
          {
            id: 3,
            src: require("@/assets/ZBpartner/img/two3.png"),
            title: "服务赋能",
            des: "针对性组织全方位培训<br />对项目提供协同与复盘服务",
          },
          {
            id: 4,
            src: require("@/assets/ZBpartner/img/two4.png"),
            title: "技术赋能",
            des: "丰富便捷的API接口、专业活跃的开发者<br />社区及时响应的技术支持",
          },
        ],
        threeList: [
          {
            id: 1,
            src: require("@/assets/ZBpartner/img/three1.png"),
            title: "提交申请",
            des: "填写信息申请成为誉照科技合作伙伴",
            small: true,
          },
          {
            id: 2,
            src: require("@/assets/ZBpartner/img/three2.png"),
            title: "初步对接",
            des: "了解需求与验证资源后达成合作",
            small: false,
          },
          {
            id: 3,
            src: require("@/assets/ZBpartner/img/three3.png"),
            title: "成果产出",
            des: "针对性系统解决方案",
            small: false,
          },
          {
            id: 4,
            src: require("@/assets/ZBpartner/img/three4.png"),
            title: "组织培训",
            des: "组织全方位培训，定期参观工场、项目、总部",
            small: false,
          },
          {
            id: 5,
            src: require("@/assets/ZBpartner/img/three5.png"),
            title: "共创共赢",
            des: "联合营销，实现价值共生与协同进化",
            small: true,
          },
          {
            id: 6,
            src: require("@/assets/ZBpartner/img/three6.png"),
            title: "立即加入",
            des: "",
            small: true,
          },
        ],
        fourList: [
          {
            id: 1,
            src: require("@/assets/ZBpartner/img/four2.png"),
            title: "供应链合作",
            des: "如果你是行业供应商（充电设备零部件供应商、生产用设备供应商、IT设备供应商、物流服务商、安装服务商、各领域专业能力供应商），与誉照构筑信息共享、服务支持、并行工程、群体决策的合作关系，一起携手并进共发展。",
          },
          {
            id: 2,
            src: require("@/assets/ZBpartner/img/four2.png"),
            title: "城市合伙人",
            des: "如果你是中小运营商、公共单位、个人投资者或者是地产开发商、偏好重资产的投资公司、大型运营商，我们针对性制定光伏、充电和储能解决方案，建立城市合作，实现互利共赢。",
          },
          {
            id: 3,
            src: require("@/assets/ZBpartner/img/four2.png"),
            title: "技术合作",
            des: "如果你是软件设计开发商，擅于研究平台互联互通、测试认证、联合研发，诚邀您与我们一同促进行业技术发展，为零碳技术领域开辟新的阳光大道。",
          },
        ],
        fiveList: [
          {
            id: 1,
            src: require("@/assets/ZBpartner/img/tuya.png"),
            img: require("@/assets/ZBpartner/img/avatar.png"),
            title: "涂鸦智能",
            des: "誉照联合涂鸦共同打造智联万物的云产品及解决方案，打造互联互通的开发标准，通过技术应用使广大用户在安全、稳定的云环境之上，生活更为便利。智能解决方案赋能客户提升产品价值，为数字化业务与现代化应用浇筑坚实的基础，拓宽低碳智能生态的阳光大道。",
          },
          {
            id: 2,
            src: require("@/assets/ZBpartner/img/huawei.png"),
            img: require("@/assets/ZBpartner/img/avatar.png"),
            title: "华为",
            des: "誉照联合华为为持续发力电动充电基础设施领域，在交通脱碳进程中，一起以创新科技推动电基础设施升级，解决车主充电痛点，拓宽市场需求，顺应“新基建”潮流开辟了充电体验的新境界。",
          },
          {
            id: 3,
            src: require("@/assets/ZBpartner/img/jingke.png"),
            img: require("@/assets/ZBpartner/img/avatar.png"),
            title: "晶科能源",
            des: "在环境可持续性方面我们与晶科不约而同达成了共识，与晶科合作向全球输送清洁能源产品，布局光伏建筑一体化，帮助机构、医院、家庭实现清洁能源自发自用，共同打造新能源生态圈。",
          },
        ],
        sixList: [
          {
            id: 1,
            src: require("@/assets/ZBpartner/img/xingxing.png"),
          },
          {
            id: 2,
            src: require("@/assets/ZBpartner/img/huawei1.png"),
          },
          {
            id: 3,
            src: require("@/assets/ZBpartner/img/woer.png"),
          },
          {
            id: 4,
            src: require("@/assets/ZBpartner/img/yidian.png"),
          },
          {
            id: 5,
            src: require("@/assets/ZBpartner/img/jingke1.png"),
          },
          {
            id: 6,
            src: require("@/assets/ZBpartner/img/yidong.png"),
          },
          {
            id: 7,
            src: require("@/assets/ZBpartner/img/lkk.png"),
            size: "medium",
          },
          {
            id: 8,
            src: require("@/assets/ZBpartner/img/diwen.png"),
            size: "small",
          },
          {
            id: 9,
            src: require("@/assets/ZBpartner/img/tuya2.png"),
          },
          {
            id: 10,
            src: require("@/assets/ZBpartner/img/taixi.png"),
            size: "medium",
          },
          {
            id: 11,
            src: require("@/assets/ZBpartner/img/zhengtai.png"),
            size: "large",
          },
          {
            id: 12,
            src: require("@/assets/ZBpartner/img/zhanyu.png"),
            size: "larger",
          },
        ],
      };
    }
    mounted() {
      this.$nextTick(() => {
        // 一旦完成界面更新, 立即执行回调
        new Swiper(".swiper", {
          // autoplay: {
          //   delay: 5000,
          // },
          autoplay: false,
          navigation: {
            nextEl: ".custom-swiper-button-next",
            prevEl: ".custom-swiper-button-prev",
          },
          loop: true,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        });
        // new Swiper(".swiper-one", {
        //   autoplay: {
        //     delay: 100,
        //   },
        //   slidesPerView: 'auto',
        //   // autoplay: true,
        //   navigation: {
        //     nextEl: ".custom-swiper-button-next",
        //     prevEl: ".custom-swiper-button-prev",
        //   },
        //   loop: true,
        //   pagination: {
        //     el: ".swiper-pagination",
        //     clickable: true,
        //   },
        // });
      });
    }
  }
</script>

<style lang="less" scoped>
  .pic-box {
    width: 100%;
    max-width: 1200px;
    margin: 80px auto;
    /deep/.section-picwall {
      height: 405px;
    }
  }
  .pic2-box {
    max-width: 1138px;
    margin-top: 40px;
    /deep/.section-picwall {
      height: auto;
      //
    }
  }
  .thumbnail {
    width: 100%;
    margin: 80px 0;
    ul {
      width: 100%;
      max-width: 1200px;
      flex: 1;
      justify-content: space-between;
      margin: 0 auto;
      padding: 0;
      li {
        width: 17.5%;
        justify-content: center;
        box-shadow: 0 0 10px #eee;
        padding: 0 10px 26px;
        .el-image {
          width: 120px;
          height: 120px;
          margin-bottom: 31px;
        }
        p {
          flex-direction: column;
          color: #666;
          font-size: 18px;
          text-align: center;
          color: #666;
          margin: 0;
          span {
            font-size: 16px;
          }
          .p-title {
            font-size: 24px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
  .company {
    .swiper {
      width: max-content;
      // overflow: auto;
      max-height: 88px;
      margin-top: 80px;

      .swiper-item {
        width: max-content;
        height: 88px;
        margin-right: 14rem;
      }
      .swiper-cnt {
        height: 100%;
        align-items: center;
      }
    }
    .swiper:hover {
      animation-play-state: paused;
    }
    .swiper-one {
      animation: like-swiper-one 10s linear infinite;
      animation-fill-mode: forwards;
      -webkit-animation-fill-mode: forwards;
    }
    .swiper-two {
      width: max-content;
      animation: like-swiper-two 10s linear infinite;
      animation-fill-mode: forwards;
      -webkit-animation-fill-mode: forwards;
      margin-top: 73px;
    }
  }
  /deep/.section-picwall {
    margin-top: 0;
  }
  .partner-mode {
    // overflow: hidden;
    width: 100%;
    position: relative;
    margin-top: 80px;
    // margin-bottom: ;
    .bg {
      // position: absolute;
      width: 100%;
      height: 553px;
      // top: 0;
      // left: 0;
      // background: url("~@/assets/partner_bg2.png") no-repeat;
    }
    .mode-box {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      margin-bottom: 120px;
      .h4 {
        color: #fff;
        margin: 134px auto 172px;
      }
      .mode-list {
        position: relative;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        &::after {
          content: "";
          display: block;
          padding-bottom: 27.75%;
        }
        .list {
          position: absolute;
          width: 100%;
          top: 0;
          left: 0;
          justify-content: space-between;
          height: 100%;
          z-index: 1;
          margin: 0;
          .item {
            position: relative;
            width: calc(33.33% - 36.5px);
            height: 100%;
            // border: 1px solid #e4ccae;
            box-shadow: 0px 2px 20px 0px rgba(65, 39, 15, 0.18);
            border-radius: 14px;
            flex-direction: column;
            align-items: center;
            overflow: hidden;
            background: #fff;
            cursor: pointer;
            > .el-image {
              height: 70.5%;
            }
            > h5 {
              flex: 1;
              align-items: center;
              font-size: 28px;
              color: #333;
              margin: 0;
            }
            &:hover {
              .modal {
                top: 0;
              }
            }
            .modal {
              position: absolute;
              top: 100%;
              width: 100%;
              height: 100%;
              background: #fff;
              padding: 30px;
              flex-direction: column;
              transition: ease-in-out all 0.5s;
              > h5 {
                font-size: 28px;
                color: #333;
                margin-bottom: 32px;
                margin-top: 0;
              }
              > p {
                text-align: left;
                font-size: 14px;
                color: #333;
                line-height: 24px;
                margin-bottom: 42px;
                margin-top: 0;
              }
              .icon {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 26px;
                width: 56px;
                height: 56px;
                align-items: center;
                justify-content: center;
                border: 1px solid #666666;
                border-radius: 50%;
                margin: 0 auto;
                i {
                  font-size: 40px;
                }
              }
            }
          }
        }
      }
    }
  }
  .p-swiper {
    width: 100%;
    position: relative;
    margin-top: 30px;
    .p-swiper-box {
      width: 100%;
      max-width: 1000px;
      border-radius: 30px;
      border: 1px solid #df9749;
      margin: 0 auto;
      overflow: hidden;
      .swiper-img {
        width: 428px;
        height: 280px;
        overflow: hidden;
        // border-radius: 30px 0 0 30px;
      }
      .swiper-text {
        flex: 1;
        // height: 100%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-left: 58px;
        > .h4 {
          font-size: 24px;
          margin-bottom: 30px;
          display: flex;
          align-items: center;
          .el-image {
            width: 42px;
            height: 42px;
            margin-right: 5px;
          }
        }
        p {
          max-width: 486px;
          font-size: 18px;
          line-height: 35px;
          text-align: left;
          margin: 0;
        }
      }
    }
    .custom-swiper-button-prev,
    .custom-swiper-button-next {
      cursor: pointer;
      width: 54px;
      height: 54px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 80px;
      transform: translateY(-50%);
      border: 1px solid #eee;
      align-items: center;
      justify-content: center;
      i {
        font-size: 24px;
      }
    }
    .custom-swiper-button-next {
      right: 80px;
      left: auto;
    }
  }
  /deep/.partner-mode + .section-main-slogan {
    margin-top: 160px;
  }

  @keyframes like-swiper-one {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-1884px);
    }
  }
  @keyframes like-swiper-two {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-1862px);
    }
  }

  @media screen and(max-width: 1024px) {
    /deep/.top {
      height: 440rem;

      .modal {
        box-sizing: border-box;
        padding: 0 24rem;
        .box {
          .h4 {
            font-size: 22rem;
            margin-bottom: 20rem;
          }
          p {
            max-width: 327rem;
          }
          .bg-title {
            width: 100%;
            font-size: 11rem;
            line-height: 15rem;
          }
        }
      }
    }

    /deep/.section-main-slogan {
      h4 {
        font-size: 22rem;
        margin: 42rem auto 60rem;
      }
      p {
        font-size: 11rem;
      }
    }

    .one {
      .list {
        padding: 0 58rem;
        .item {
          margin-bottom: 42rem;
          .item-left {
            flex-shrink: 0;
            margin-right: 35rem;
            .el-image {
              width: 53rem;
              height: auto;
              // height: 53rem;
            }
          }
          .item-right {
            flex: 1;
            flex-shrink: 0;
            text-align: left;
            h5 {
              font-size: 18rem;
              line-height: 18rem;
              margin: 0 0 10rem;
            }
            p {
              font-size: 11rem;
              margin: 0;
            }
          }
        }
      }
    }

    .two {
      background-color: #ebebeb;
      padding-bottom: 30rem;
      /deep/.section-main-slogan h4 {
        margin: 30rem auto 45rem;
      }
      .list {
        overflow: auto;
        padding: 0 24rem;
        .item {
          background-color: #ffffff;
          text-align: left;
          padding: 14rem 24rem;
          margin-bottom: 10rem;
          .item-left {
            margin-right: 15rem;
            .el-image {
              width: 50rem;
              height: auto;
              &.bg {
                width: 40rem;
                padding: 0 5rem;
              }
            }
          }
          .item-right {
            flex: 1;
            flex-shrink: 0;
            h5 {
              font-size: 18rem;
              line-height: 18rem;
              margin: 0 0 10rem;
            }
            p {
              font-size: 11rem;
              margin: 0;
            }
          }
        }
      }
    }
    .three {
      background-color: #fff;
      /deep/.section-main-slogan h4 {
        margin: 30rem auto 45rem;
      }
      .list {
        padding: 0 25rem;
        .item {
          background-color: #f6f6f6;
          text-align: left;
          padding: 16rem 20rem;
          margin-bottom: 18rem;
          .item-left {
            align-items: center;
            margin-right: 15rem;
            .el-image {
              width: 24rem;
              height: auto;
              padding: 0 2rem;
              &.bg {
                width: 28rem;
                padding: 0;
              }
            }
          }
          .item-right {
            flex: 1;
            flex-shrink: 0;
            h4 {
              font-size: 18rem;
              line-height: 18rem;
              margin: 0 0 6rem;
            }
            p {
              font-size: 11rem;
              margin: 0;
            }
          }
        }
      }
    }
    .four {
      position: relative;
      background-color: #ebebeb;
      overflow: auto;
      margin-top: 50rem;
      .bg {
        position: absolute;
        width: 100%;
        height: 326rem;
        top: 0;
        left: 0;
      }
      .four-modal {
        position: relative;
        /deep/.section-main-slogan {
          h4 {
            color: #fff;
            margin: 38rem auto;
          }
        }
        .list {
          padding: 0 24rem;
          .item {
            align-items: center;
            text-align: left;
            background-color: #fff;
            border-radius: 6rem;
            padding: 10rem 17rem;
            margin-bottom: 7rem;
            .item-left {
              flex-direction: column;
              flex: 1;
              flex-shrink: 0;
              h5 {
                font-size: 14rem;
                margin: 0 0 5.8rem;
              }
              p {
                max-width: 243rem;
                font-size: 8rem;
                margin: 0;
              }
            }
            .item-right {
              flex-shrink: 0;
              margin-left: 24rem;
              .el-image {
                width: 23rem;
                height: auto;
              }
            }
          }
        }
      }
    }
    .five {
      background-color: #ebebeb;
      padding-bottom: 30rem;
      /deep/.section-main-slogan h4 {
        margin: 30rem auto 45rem;
      }
      .list {
        overflow: auto;
        padding: 0 24rem;
        .item {
          text-align: left;
          border: 1px solid #f38200;
          border-radius: 10rem;
          overflow: hidden;
          margin-bottom: 12rem;
          .item-left {
            margin-right: 15rem;
            .el-image {
              width: 118rem;
              height: auto;
            }
          }
          .item-right {
            padding: 11rem 8rem;
            flex: 1;
            flex-shrink: 0;
            .name {
              align-items: center;
              .el-image {
                width: 21rem;
              }
              h6 {
                font-size: 12rem;
                line-height: 18rem;
                margin: 0;
              }
            }
            p {
              font-size: 8rem;
              text-align: justify;
              margin: 0;
            }
          }
        }
      }
    }
    .six {
      /deep/.section-main-slogan {
        h4 {
          margin: 28rem auto 44rem;
        }
      }
      .list {
        flex-wrap: wrap;
        padding: 0 30rem 24rem;

        .item {
          width: 50%;
          height: 60rem;
          align-items: center;
          justify-content: flex-start;
          padding: 12rem 0;
          margin-bottom: 12rem;
          &:nth-child(2n) {
            padding-left: 12rem;
          }

          .el-image {
            width: auto;
            // max-width: 136rem;
            // height: auto;
            &.medium {
              width: 100rem;
            }
            &.small {
              width: 88rem;
            }
            &.large {
              width: 88rem;
              height: auto;
            }
            &.larger {
              width: 110rem;
              height: auto;
              margin-left: -10rem;
            }
            // height: 25rem;
          }
        }
      }
    }

    .wall {
      height: 216rem;
      position: relative;
      color: #fff;

      .modal {
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h4 {
          font-size: 22rem;
          margin: 0 0 16rem;
        }
        p {
          max-width: 327rem;
          font-size: 11rem;
          margin: 0 0 32rem;
        }
      }

      .bg {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
</style>
